"use client";

import type { AnalyticsEvent, HallowAnalyticsEvent } from "@packages/analytics";
import { useAnalytics } from "@packages/analytics";
import { Link, useTranslations } from "@packages/i18n";
import type { ButtonProps } from "@packages/ui";
import { Button } from "@packages/ui";
import type { PropsWithChildren } from "react";

export type CTAProps = Omit<ButtonProps, "onClickCapture"> &
  PropsWithChildren<{
    href: string;
    event: HallowAnalyticsEvent;
    eventProperties: AnalyticsEvent["properties"];
  }>;

export const CTA = ({
  href,
  event,
  eventProperties,
  children,
  ...props
}: CTAProps) => {
  const t = useTranslations();
  const analytics = useAnalytics();

  return (
    <Button
      asChild
      {...(props as ButtonProps)}
      onClickCapture={() =>
        analytics.track({ event, properties: eventProperties })
      }
    >
      <Link href={href}>{children ?? t("try_hallow_for_free")}</Link>
    </Button>
  );
};
